import { Checkbox, Form, InputNumber, Select, TimePicker } from "antd"
import { useEffect, useState } from "react"
import { DayOfWeek, ScheduledType } from "../../api/api"

export const SchedulingOptions = () => {
  const [form] = Form.useForm()
  const [currentScheduledType, setCurrentScheduledType] =
    useState<ScheduledType>()

  // Sync the initial value of scheduledType with the form
  useEffect(() => {
    const initialScheduledType = form.getFieldValue("scheduledType")
    setCurrentScheduledType(initialScheduledType)
  }, [form])

  const onScheduledTypeChange = (value: ScheduledType) => {
    setCurrentScheduledType(value)
  }

  return (
    <Form form={form} initialValues={{ scheduledType: ScheduledType.DayOfWeek }}>
      <h2>Scheduling Options</h2>
      <Form.Item
        label="When to run"
        name="scheduledType"
        tooltip={
          "You can either run once a month or on certain days of the week"
        }
      >
        <Select<ScheduledType>
          style={{ width: "500px" }}
          onChange={onScheduledTypeChange}
        >
          <Select.Option value={ScheduledType.DayOfWeek}>Week</Select.Option>
          <Select.Option value={ScheduledType.DayOfMonth}>Month</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Days of Week"
        name="daysOfTheWeek"
        hidden={currentScheduledType !== ScheduledType.DayOfWeek}
      >
        <Checkbox.Group>
          <Checkbox value={DayOfWeek.Monday}>Monday</Checkbox>
          <Checkbox value={DayOfWeek.Tuesday}>Tuesday</Checkbox>
          <Checkbox value={DayOfWeek.Wednesday}>Wednesday</Checkbox>
          <Checkbox value={DayOfWeek.Thursday}>Thursday</Checkbox>
          <Checkbox value={DayOfWeek.Friday}>Friday</Checkbox>
          <Checkbox value={DayOfWeek.Saturday}>Saturday</Checkbox>
          <Checkbox value={DayOfWeek.Sunday}>Sunday</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        label="Day of Month"
        name="dayOfTheMonth"
        hidden={currentScheduledType !== ScheduledType.DayOfMonth}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item label="Time" name="timeToRun">
        <TimePicker showSecond={false} minuteStep={5} />
      </Form.Item>
    </Form>
  )
}
